import * as React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import type { RoomsRoom as RoomsRoomType } from './rooms.room';
import { RoomsRoom } from './rooms.room';
import type { ShopFormRoom } from './types';

export type Rooms = Pick<
  RoomsRoomType,
  'ageRange' | 'agesRequired' | 'maxRooms' | 'maxOccupants' | 'isAdultsOnly' | 'adultAge'
> & {
  /**
   * Required string for message value displayed when the max-occupancy of a room is reached.<br><br>
   * Recommended values from 'osc-shop-form' resource bundle: <br>
   * <code> const { t } = useTranslation('osc-rooms'); </code><br>
   *  • base: <b>t('occupancy.occupancyLimitMessage') </b> <br>
   * -returns: "You've reached the maximum number of guests for a single room. Please add another room."<br>
   *  • find nearby: <b>t('occupancy.findNearbyOccupancyLimitMessage') </b> <br>
   * -returns: "You've reached the maximum number of guests for a single room. Please add another room or adjust your search to find nearby hotels with larger room capacities."
   * <br><br>
   */
  occupancyLimitMessage: React.ReactNode;
};

export const newRoom = Object.freeze({ adults: 1, children: [] });
export const Rooms: React.FC<React.PropsWithChildren<Rooms>> = ({
  adultAge: adultAgeProp,
  agesRequired,
  ageRange,
  maxRooms = 9,
  maxOccupants = 8,
  occupancyLimitMessage,
  isAdultsOnly,
}) => {
  const adultAge = adultAgeProp || ageRange?.max;
  const [t] = useTranslation('osc-rooms');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { fields, append, remove } = useFieldArray({ name: 'rooms' });
  const rooms: ShopFormRoom[] = useWatch({ name: 'rooms' });
  const reachedMaxOccupants =
    occupancyLimitMessage &&
    rooms?.some((room) => room.adults + room.children.length === maxOccupants);
  const canOnlyBookSingleRoom = maxRooms === 1;

  const handleRemoveRoom = (val: number) => {
    remove(val);
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="flex" aria-hidden>
          <span className="w-1/3 font-bold ltr:text-left rtl:text-right">
            {t('occupancy.roomHeader')}
          </span>
          <span className="w-1/3 text-center font-bold">
            {t('occupancy.adultHeader')}
            {adultAge && <span className="pl-1 text-sm font-normal">({adultAge}+)</span>}
          </span>
          <span className="w-1/3 text-center font-bold">{t('occupancy.childHeader')}</span>
        </div>
      </div>
      <div data-osc-product="rooms" className="w-full space-y-4 py-3" data-testid="rooms">
        {fields.map((field, index) => (
          <RoomsRoom
            key={field.id}
            index={index}
            ageRange={ageRange}
            onRemoveRoom={handleRemoveRoom}
            agesRequired={agesRequired}
            maxOccupants={maxOccupants}
            isAdultsOnly={isAdultsOnly}
            adultAge={adultAge}
          />
        ))}
      </div>
      {isAdultsOnly && adultAge ? (
        <p className="pb-3 text-xs">{t('occupancy.adultsOnlyNote', { adultAge })}</p>
      ) : null}
      {reachedMaxOccupants ? (
        <div className="flex w-full justify-start pb-3">
          <p className="break-words text-xs" aria-live="assertive" role="alert">
            {occupancyLimitMessage}
          </p>
        </div>
      ) : null}
      <div className="border-border space-y-2 border-t border-solid py-2">
        {canOnlyBookSingleRoom ? (
          <p className="text-xs">{t('occupancy.singleRoomOnly')}</p>
        ) : (
          <button
            type="button"
            data-testid="rooms-add-room"
            ref={buttonRef}
            className="stroke-primary hover:stroke-primary-alt disabled:text-text-disabled disabled:stroke-text-disabled flex appearance-none items-center font-bold hover:duration-100"
            onClick={() => {
              append({ ...newRoom });
            }}
            disabled={fields.length >= maxRooms}
          >
            <svg role="img" aria-hidden viewBox="0 0 27 27" className="size-6 ltr:mr-2 rtl:ml-2">
              <g fill="none" fillRule="evenodd" transform="translate(1 .68)">
                <ellipse cx="12.5" cy="12.724" rx="12.5" ry="12.724" />
                <path strokeLinecap="square" d="M6.5 12.724h12.042M12.5 18.832V6.574" />
              </g>
            </svg>
            {t('occupancy.addRoom')}
          </button>
        )}
      </div>
    </>
  );
};

export default Rooms;
