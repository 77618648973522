import { Dialog } from '@dx-ui/osc-dialog-v2';
import type { FieldValues } from 'react-hook-form';
import { useForm, FormProvider } from 'react-hook-form';
import { FormInput, type FormDataStructure } from '@dx-ui/osc-form';
import { FilterMaxPriceRadioButtons } from '@dx-ui/osc-filters';
import { ShopFormLocation } from '../../osc-search-form/search-form-inputs';
import { useLocation } from '@dx-ui/framework-location-provider';
import { useInLanguageAutocomplete, useWrappedRouter } from '../../../hooks';
import { logError } from '@dx-ui/framework-logger';
import type { GeocodeQuery } from '../../../gql/types';
import { serverSideGeocodeQuery } from '../../../gql/queries';
import { useQueryClient } from '@tanstack/react-query';
import type { LocationUrlParams } from '@dx-ui/framework-uri-builder';
import { locationUriBuilder } from '@dx-ui/framework-uri-builder';
import { useTranslation } from 'next-i18next';

type BookWithinBudgetModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
};
const PRICE_LIST = [
  { basePrice: 100, convertedPrice: 100 },
  { basePrice: 200, convertedPrice: 200 },
  { basePrice: 350, convertedPrice: 350 },
  { basePrice: 500, convertedPrice: 500 },
];

const AMENITIES = [
  { id: 'allInclusive', name: 'All-inclusive' },
  { id: 'beach', name: 'Beach' },
  { id: 'boutique', name: 'Boutique' },
  { id: 'golf', name: 'Golf' },
  { id: 'luxury', name: 'Luxury' },
  { id: 'resort', name: 'Resort' },
];

export const PlanYourStayModal = ({ isOpen, onDismiss }: BookWithinBudgetModalProps) => {
  const { t } = useTranslation(['default-locations-page', 'osc-location']);
  const { coordinate } = useLocation();
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const queryClient = useQueryClient();
  const { isInLanguageAutocomplete, language: locale } = useInLanguageAutocomplete();

  const methods = useForm<FormDataStructure>({
    defaultValues: { priceFilter: 'noMaximum' },
    mode: 'onTouched',
  });
  const { getValues, reset } = methods;

  const handleModalClose = () => {
    reset();
    if (onDismiss) onDismiss();
  };

  const handleFindaHotelClick = async (formData: FieldValues) => {
    const locationAddress = formData?.query;
    const placeId = formData?.placeId;
    let placeUri = 'usa';
    if (!!locationAddress || !!placeId) {
      let geocodeData: GeocodeQuery = {};
      try {
        geocodeData = await serverSideGeocodeQuery(queryClient, {
          address: locationAddress,
          placeId,
          sessionToken: queryParameters?.sessionToken,
          language: isInLanguageAutocomplete && locale ? locale : 'en',
        });
        placeUri = geocodeData?.geocode?.match?.placeUri || 'usa';
      } catch (error) {
        if (error instanceof Error || typeof error === 'string')
          logError('PLAN_TO_BOOK', error, 'failed to fetch geocode data');
      }
    }
    //default Curreny is USD
    const priceFilterValue =
      formData?.priceFilter && formData?.priceFilter !== 'noMaximum'
        ? ([formData?.priceFilter, formData?.priceFilter] as Tuple<2, number>)
        : undefined;

    const urlParams: LocationUrlParams = {
      f_amenityIds: formData.amenities,
      f_price: priceFilterValue,
      placeUri,
    };
    const locationUrlString = locationUriBuilder({
      locale: router.locale || 'en',
      urlParams,
      relative: true,
    });
    handleModalClose();
    window.location.assign(locationUrlString);
  };

  return (
    <FormProvider {...methods}>
      <Dialog
        isOpen={isOpen}
        onDismiss={handleModalClose}
        size="md"
        title={t('pricePlanner.planYourStay')}
      >
        <div className="mx-4">
          <div className="mb-2 text-base font-bold"> {t('pricePlanner.priceFilterHeading')}</div>
          <div className="px-4">
            <FilterMaxPriceRadioButtons currencyCode="USD" priceList={PRICE_LIST} />
          </div>
          <div>
            <div className="my-2">
              <ShopFormLocation
                coordinate={coordinate}
                key="planToBookLocation"
                language={router.locale || 'en'}
                optional={true}
                placeholder={t('osc-location:placeholder')}
                sessionId={queryParameters?.sessionToken}
              />
            </div>
          </div>
          <div>
            <div className="py-3 text-base font-bold">
              {t('pricePlanner.amenitiesHeading')}{' '}
              <span className="text-text-alt font-normal"> {t('pricePlanner.optional')} </span>
            </div>
            <div className="grid items-start gap-6 px-2 pb-4 sm:grid-cols-1 md:grid-cols-2">
              {AMENITIES.map((amenity) => (
                <div key={amenity.name} className="flex items-center text-sm">
                  <FormInput
                    id={amenity.id}
                    label={
                      <div className="flex items-start">
                        <span className="rtl:px-2">{amenity.name}</span>
                      </div>
                    }
                    labelClassName="flex items-start"
                    name="amenities"
                    type="checkbox"
                    value={amenity.id}
                  />
                </div>
              ))}
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-xl w-full"
            onClick={() => handleFindaHotelClick(getValues())}
          >
            {t('default-locations-page:findHotel')}
          </button>
        </div>
      </Dialog>
    </FormProvider>
  );
};
