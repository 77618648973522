import { FormInput } from '@dx-ui/osc-form';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';

export type BrandFiltersProps = {
  brands?: { code: string; name: string }[];
};

const BrandFilters = ({ brands }: BrandFiltersProps) => {
  const { t } = useTranslation('osc-filters');
  return brands?.length ? (
    <div id="brands-list">
      <h3 className="label label-inline-reverse p-4 pt-3">{t('ourBrands')}</h3>
      <div className="grid gap-6 px-4 sm:grid-cols-1 md:grid-cols-2">
        {brands?.map(({ code, name }) => (
          <div key={code}>
            <FormInput
              id={name}
              label={
                <div className="flex flex-row items-center align-middle" key={code}>
                  <span className="inline-block size-6 ltr:mr-2">
                    <Image
                      alt={code}
                      aria-hidden={true}
                      src={`/modules/assets/svgs/logos/bug/${code}.svg`}
                      width={24}
                      height={24}
                    />
                  </span>
                  <span className="flex-wrap rtl:px-2">{name}</span>
                </div>
              }
              name="brands"
              type="checkbox"
              value={code}
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export { BrandFilters };
