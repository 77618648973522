import * as React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { FiltersModal } from './filters.modal';
import cx from 'classnames';
import { getActiveFilterCount } from './filters.utilities';
import type { TripAdvisorRatings } from './trip-advisor-filters';
import Icon from '@dx-ui/osc-icon';
import type { FilterPriceListOptions } from './pricing-filter';

export type DefaultFilters = {
  amenities?: string[];
  brands?: string[];
  priceFilter?: string | number | number[] | FilterPriceListOptions | undefined;
  saleFilter?: boolean;
  showAvailableHotelsOnly?: boolean;
  tripAdvisorRating?: TripAdvisorRatings;
};

type Filters = {
  /** applyBtnAccessibleLabel Defaults to "and close" */
  applyBtnAccessibleLabel?: string;
  /** Custom label for apply button in filters modal. Defaults to "Apply Filters"  */
  applyBtnLabel?: string;
  /** custom className for filter button */
  buttonClassName?: string;
  /** Label for filters button. Default to "Filters" */
  buttonLabel?: React.ReactNode;
  /** Children elements for filters modal. Can use pre-built components or custom as long as they follow react hook form patterns. */
  children: React.ReactNode;
  /** Filter selections to initialize with. Follows react hook form defaultValues pattern */
  defaultFilters?: DefaultFilters;
  /** Optional filters modal title */
  filtersModalTitle?: string;
  /** custom className for modal dialog content */
  modalContentClassName?: string;
  /** Callback to hook into filter button click event*/
  onFiltersClick?: () => void;
  /** Callback to hook into submission event on filters. Returns state of filters */
  onSubmit?: (data: FieldValues) => void;
  /** Custom label for reset button in filters modal. Defaults to "Reset Filters"  */
  resetBtnLabel?: string;
  /** Hide filter Icon, by default filter Icon is displayed */
  showFilterIcon?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

/**
 * A shared filters implementation that is intended to used across OHW UI apps to give a consistent filters experience.
 */
const Filters = React.forwardRef<HTMLButtonElement, Filters>(
  (
    {
      applyBtnAccessibleLabel,
      applyBtnLabel,
      buttonClassName,
      buttonLabel,
      children,
      defaultFilters,
      filtersModalTitle,
      modalContentClassName,
      onFiltersClick,
      onSubmit,
      resetBtnLabel,
      showFilterIcon = true,
      ...rest
    },
    ref
  ) => {
    const [t] = useTranslation('osc-filters');
    const [isOpen, setIsOpen] = React.useState(false);
    const activeFilterCount = getActiveFilterCount(defaultFilters || {});

    const handleOpenFilters = () => {
      if (onFiltersClick) {
        onFiltersClick();
      }
      setIsOpen((isOpen) => !isOpen);
    };

    const handleCloseFilters = () => {
      setIsOpen((isOpen) => !isOpen);
    };

    const handleSubmit = (data: FieldValues) => {
      if (onSubmit) onSubmit(data);
      /** Close filters modal when apply button is pressed */
      setIsOpen(false);
    };

    return (
      <FiltersModal
        applyBtnAccessibleLabel={applyBtnAccessibleLabel}
        applyBtnLabel={applyBtnLabel}
        contentClassName={modalContentClassName}
        defaultFilters={defaultFilters}
        isModalOpen={isOpen}
        onSubmit={handleSubmit}
        onCloseModal={handleCloseFilters}
        resetBtnLabel={resetBtnLabel}
        title={filtersModalTitle}
        dialogTrigger={
          <button
            {...rest}
            className={cx('btn btn-primary-outline flex content-center gap-0.5', buttonClassName)}
            onClick={handleOpenFilters}
            type="button"
            ref={ref}
          >
            {showFilterIcon ? <Icon name="filters" size="sm" /> : null}
            {/* Show filters text if there are no active filters, otherwise show filters text + active filter count */}
            {activeFilterCount === 0
              ? buttonLabel || t('filters')
              : buttonLabel || t('openFilterPanelText', { count: activeFilterCount })}
          </button>
        }
      >
        {children}
      </FiltersModal>
    );
  }
);

Filters.displayName = 'Filters';

export { Filters };
export default Filters;
