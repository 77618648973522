import * as React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from 'next-i18next';
import { ControlClose } from '@dx-ui/osc-controls';
import cx from 'classnames';

export type DialogSizes =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | 'full'
  | 'container';

export type DialogBaseProps = {
  /** The className for the CloseButton to support custom curated sites */
  closeButtonClassName?: string;
  /** The className for the dialog tag */
  dialogClassName?: string;
  /** The className for the dialog overlay tag */
  dialogOverlayClassName?: string;
  /** The className for the dialog title tag */
  dialogTitleClassName?: string;
  /** The element that opens the dialog that focus should return to */
  dialogTrigger?: React.ReactNode;
  /** The size of the modal. */
  size?: DialogSizes;
  /** Allows passing of additional JSX to dialog. */
  bottomContent?: React.ReactNode;
  /** Whether the overlay is open by default (controlled). */
  isOpen?: boolean;
  /** Handler that is called when the overlay is dismissed. */
  onDismiss?: () => void;
  /** Sets whether header should be sticky in overflow scroll situations when a title is passed. Defaults to false */
  stickyHeader?: boolean;
} & React.ComponentProps<'div'>;

export type DialogOptionalProps =
  | { ariaLabel?: never; title: string; hasChildrenWithTitle?: never }
  | { ariaLabel: string; title?: never; hasChildrenWithTitle?: never }
  | { ariaLabel?: never; title?: never; hasChildrenWithTitle: boolean };

export const DialogTitle = Dialog.Title;

export const DialogBase = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<DialogBaseProps & DialogOptionalProps>
>(
  (
    {
      ariaLabel,
      children,
      className,
      closeButtonClassName,
      dialogClassName,
      dialogOverlayClassName,
      dialogTitleClassName,
      dialogTrigger,
      isOpen,
      title,
      size = 'md',
      bottomContent,
      onDismiss,
      hasChildrenWithTitle,
      stickyHeader = false,
    },
    forwardedRef
  ) => {
    const innerRef = React.useRef<React.ElementRef<'div'>>(null);
    const dialogRef = React.useRef<React.ElementRef<'div'>>(null);

    React.useImperativeHandle(forwardedRef, () => dialogRef.current as HTMLDivElement);

    return (
      <Dialog.Root open={isOpen}>
        {dialogTrigger ? <Dialog.Trigger asChild>{dialogTrigger}</Dialog.Trigger> : null}
        <Dialog.Portal>
          <Dialog.Overlay className={cx('dialog-overlay sm:py-4 lg:py-12', dialogOverlayClassName)}>
            <Dialog.Content
              /* This is disabled on purpose because we don't use radix-ui/dialog description. See https://www.radix-ui.com/primitives/docs/components/dialog#description */
              aria-describedby={undefined}
              className={cx(
                'dialog-content-v2 max-h-full rounded-lg sm:max-h-[95vh] md:max-h-[90vh]',
                {
                  'max-w-xs': size === 'xs',
                  'max-w-sm': size === 'sm',
                  'max-w-md': size === 'md',
                  'max-w-lg': size === 'lg',
                  'max-w-xl': size === 'xl',
                  'max-w-2xl': size === '2xl',
                  'max-w-3xl': size === '3xl',
                  'max-w-4xl': size === '4xl',
                  'max-w-5xl': size === '5xl',
                  'max-w-6xl': size === '6xl',
                  'max-w-full': size === 'full',
                  'w-full lg:max-w-[min(90%,calc(1440px-5rem))]': size === 'container',
                  'pt-0': stickyHeader,
                  'pb-0': bottomContent,
                },
                dialogClassName
              )}
              onInteractOutside={onDismiss}
              onEscapeKeyDown={onDismiss}
            >
              <div className="h-full" ref={innerRef}>
                {hasChildrenWithTitle ? null : title ? (
                  <div
                    className={cx('border-border relative mb-2 border-b pb-3.5', {
                      'sticky top-0 bg-bg z-10 pt-4': stickyHeader,
                    })}
                  >
                    <Dialog.Title
                      className={cx(
                        'brand-wa:font-light brand-wa:text-text brand-wa:text-xl brand-wa:md:text-2xl brand-ou:text-primary mx-4 text-center font-bold',
                        dialogTitleClassName
                      )}
                      data-osc-product="osc-dialog-title"
                    >
                      {title}
                    </Dialog.Title>
                    {onDismiss && (
                      <CloseButton
                        className={closeButtonClassName}
                        onDismiss={onDismiss}
                        stickyHeader={stickyHeader}
                      />
                    )}
                  </div>
                ) : (
                  <Dialog.Title aria-label={ariaLabel} className="hidden" />
                )}
                {!title && onDismiss ? (
                  <div className="relative py-1">
                    <CloseButton onDismiss={onDismiss} stickyHeader={stickyHeader} />
                  </div>
                ) : null}
                <div className={cx('p-1', className)}>{children}</div>
                {bottomContent}
              </div>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }
);

DialogBase.displayName = 'DialogBase';

function CloseButton({
  onDismiss,
  stickyHeader,
  className,
}: Pick<DialogBaseProps, 'onDismiss' | 'stickyHeader'> & { className?: string }) {
  const { t } = useTranslation('osc-dialog');
  return (
    <Dialog.Close asChild>
      <ControlClose
        onClick={onDismiss}
        className={cx(
          'absolute -end-2',
          {
            '-top-1.5': !stickyHeader,
            'top-2': stickyHeader,
          },
          className
        )}
        label={t('close')}
      />
    </Dialog.Close>
  );
}
