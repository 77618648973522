import { useState } from 'react';
import { PlanYourStayModal } from './plan-your-stay-modal';
import { useTranslation } from 'next-i18next';

export const PlanToBookWithinBudgetButton = () => {
  const { t } = useTranslation(['default-locations-page']);
  const [showBookWithinBudgetModal, setShowBookWithinBudgetModal] = useState(false);

  const openBookWithinBudgetModal = () => {
    setShowBookWithinBudgetModal(true);
  };
  const closeBookWithinBudgetModal = () => {
    setShowBookWithinBudgetModal(false);
  };
  return (
    <>
      <div className="relative flex flex-wrap items-end justify-center gap-2 sm:justify-start lg:mb-6 lg:flex-nowrap lg:gap-3">
        <div className="flex w-full max-w-sm pt-2 md:w-auto md:flex-1">
          <button
            className="btn btn-primary-outline w-full py-2 md:w-auto md:flex-1"
            onClick={openBookWithinBudgetModal}
            type="button"
          >
            {t('pricePlanner.bookWithinBudgetCta')}
          </button>
        </div>
      </div>
      <PlanYourStayModal
        isOpen={showBookWithinBudgetModal}
        onDismiss={closeBookWithinBudgetModal}
      />
    </>
  );
};
