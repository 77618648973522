import { useState } from 'react';
import { FLEX_DATES_VERBIAGE } from '../constants';
import { useGetMVTSelections } from './use-get-mvt-selections';
import { useTranslation } from 'react-i18next';

export const useFlexDateVerbiageMVT = () => {
  const [enableFlexDateVerbiage, setEnableFlexDateVerbiage] = useState(false);
  const { t } = useTranslation('osc-shop-form-wrapper');
  const {
    isLoaded: isFlexDatesVerbiageTestLoaded,
    isMVTVariant: { a: isFlexDatesVerbiageVarA, b: isFlexDatesVerbiageVarB },
  } = useGetMVTSelections({
    agentId: FLEX_DATES_VERBIAGE.agentId,
    isProvisional: enableFlexDateVerbiage,
  });
  const isFlexDatesVerbiage = isFlexDatesVerbiageVarA || isFlexDatesVerbiageVarB;
  const flexDatesLabel = t('shopByPrice');
  return {
    isFlexDatesVerbiage,
    isFlexDatesVerbiageVarB,
    isFlexDatesVerbiageTestLoaded,
    setEnableFlexDateVerbiage,
    flexDatesLabel,
  };
};
