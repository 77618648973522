import type { HotelAmenityId } from '@dx-ui/gql-types';
import { FormInput } from '@dx-ui/osc-form';
import Icon, { AmenityIcons } from '@dx-ui/osc-icon';
import { InfoPopup } from '@dx-ui/osc-info-popup';
import { useTranslation } from 'next-i18next';

export type AmenityFiltersProps = {
  amenities?: { id: string; name: string; hint?: string | null }[];
  amenityCategories?: { id?: string | null; name?: string | null; amenityIds: string[] | null }[];
};

const AmenityFilters = ({ amenities, amenityCategories }: AmenityFiltersProps) => {
  const { t } = useTranslation('osc-filters');

  return (
    <div className="divide-border-alt mt-4 divide-y" id="amenities-list">
      {amenityCategories?.map(({ name, amenityIds }, idx) => {
        //do not return category if has no children
        return amenities?.some((amenity) => amenityIds?.includes(amenity?.id)) ? (
          <div key={name ?? idx}>
            <h3 className="label label-inline-reverse p-4 pt-3">{name}</h3>
            <div className="grid items-start gap-6 px-4 pb-4 sm:grid-cols-1 md:grid-cols-2">
              {amenityIds?.map((amenityId) => {
                const amenity = amenities?.find((amenity) => amenity.id === amenityId);
                const amenityName = amenity?.name;
                const hint = amenity?.hint;
                return amenityName ? (
                  <div className="flex items-center" key={amenityId}>
                    <FormInput
                      id={amenityId}
                      label={
                        <div className="flex items-start">
                          <div className="-mt-1 shrink-0 ltr:mr-2">
                            <Icon name={AmenityIcons[amenityId as HotelAmenityId]} size="lg" />
                          </div>
                          <span className="rtl:px-2">{amenityName}</span>
                        </div>
                      }
                      labelClassName="flex items-start"
                      name="amenities"
                      type="checkbox"
                      value={amenityId}
                    />
                    {hint ? (
                      <div className="pl-2">
                        <InfoPopup label={t('amenityInformation', { amenityName })}>
                          <span className="flex w-40 lg:w-60">{hint}</span>
                        </InfoPopup>
                      </div>
                    ) : null}
                  </div>
                ) : null;
              })}
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
};

export { AmenityFilters };
