import { useTranslation } from 'next-i18next';
import { FormInput } from '@dx-ui/osc-form';
import { useFormContext } from 'react-hook-form';
import { PriceFilterSkeleton } from './price-filter-skeleton';
import * as React from 'react';
import Icon from '@dx-ui/osc-icon';
import type { FilterPriceListOptions } from './filter-max-price-radio-buttons';
import { FilterMaxPriceRadioButtons } from './filter-max-price-radio-buttons';

export type PricingFilterProps = {
  /**Currency symbol to label input field and description for accessibility */
  currency: { symbol: string; description: string; code: string };
  /**Disable price filter elements   */
  hidePriceFilter?: boolean;
  /**Price filter loading state, will active shimmer if true*/
  isLoading?: boolean;
  /**
   * Array of price consideration messages that will appear under the price filter header.<br/>
   * Suggested values from OSC filters resource bundle:<br/>
   * <code>osc-filters: perNightPriceFilterMessage - "You are filtering on a per night price"</code><br/>
   * <code>osc-filters: daytimeUsePriceMessage - "You are filtering a daytime use price."</code>
   */
  priceConsiderationMessage?: string[];
  /**Active price filter state converted price value to set input value on reopen<br/>
   * recommend value: <code>[filterstate].priceFilter.convertedPrice</code>
   */
  priceFilter?: string;
  /**Object array for filter options display and numerical values <br/>
   * <code>[{ basePrice: number; convertedPrice: number;}]</code>
   */
  priceListOptions: FilterPriceListOptions[];
  /**Sale filter active */
  isSaleFilter?: boolean;
  /**Toggle sale filter checkbox */
  showSaleFilter?: boolean;
  /** NHCSEARCH-5310 MVT - max points filter */
  isPointsFilter?: boolean;
};
export const PricingFilter = ({
  currency,
  hidePriceFilter = false,
  isLoading,
  priceConsiderationMessage,
  priceFilter = 'noMaximum',
  priceListOptions,
  isSaleFilter,
  showSaleFilter = true,
  isPointsFilter = false,
}: PricingFilterProps) => {
  const { t } = useTranslation('osc-filters');
  const { setValue } = useFormContext();
  React.useEffect(() => {
    if (priceFilter) setValue('priceFilter', priceFilter);
    if (showSaleFilter && isSaleFilter) setValue('saleFilter', true);
  }, [isSaleFilter, priceFilter, setValue, showSaleFilter]);

  if (isLoading) return <PriceFilterSkeleton />;
  return (
    <>
      <h3 className="label label-inline-reverse text-base font-semibold">
        {isPointsFilter ? t('maxPointsPerNight') : t('maxPriceRangeLabel')}
      </h3>
      {!!priceConsiderationMessage &&
        priceConsiderationMessage.map((message) =>
          message ? (
            <div className="text-text-alt text-sm" key={message}>
              {message}
            </div>
          ) : null
        )}
      <div className="mt-3 flex w-auto flex-col items-start space-y-3">
        <FilterMaxPriceRadioButtons
          currencyCode={currency.code}
          isPointsFilter={isPointsFilter}
          hidePriceRadioButtons={hidePriceFilter}
          priceList={priceListOptions}
        />
        {showSaleFilter ? (
          <div>
            <FormInput
              type="checkbox"
              containerClassName="flex items-center"
              label={
                <div className="flex items-center">
                  <span className="flex size-6 shrink-0 items-center">
                    <Icon name="sale" size="md" />
                  </span>
                  <span className="px-2">{t('showSaleRates')}</span>
                </div>
              }
              labelClassName="flex items-start"
              name="saleFilter"
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
